var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"历史行程","width":"900px","visible":_vm.visible,"top":"5vh"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-row',{attrs:{"type":"flex","justify":"space-between","align":"center"}},[_c('div',[_c('span',[_vm._v("查询时间：")]),_c('el-date-picker',{attrs:{"type":"datetimerange","range-separator":"至","start-placeholde":"开始时间","end-placeholde":"结束时间"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('span',{staticClass:"i-link iml-0",on:{"click":_vm.setRangeToday}},[_vm._v("今天")]),_c('span',{staticClass:"i-link",on:{"click":_vm.setRangeYesterday}},[_vm._v("昨天")]),_c('span',{staticClass:"i-link",on:{"click":_vm.setRangeBeforeYesterday}},[_vm._v("前天")])],1),_c('el-button',{staticClass:"border",attrs:{"type":"primary","plain":""},on:{"click":_vm.fetchData}},[_vm._v("查询")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticClass:"v-table__customStyle",staticStyle:{"margin-top":"12px"},attrs:{"data":_vm.list,"height":"500px","element-loading-text":"拼命加载中"}},[_c('el-table-column',{attrs:{"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"时长","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"clock"},[_c('i-icon',{attrs:{"name":"icon-clock"}}),_vm._v(_vm._s(row.StartTimeDesc)+"~"+_vm._s(row.EndTimeDesc))],1)]}}])}),_c('el-table-column',{attrs:{"label":"位置"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"position"},[_c('i-icon',{staticClass:"blue",attrs:{"name":"icon-map-marker"}}),_vm._v("从:"),_c('span-address',{attrs:{"lon":row.Lon_B,"lat":row.Lat_B,"show-default":""}})],1),_c('p',{staticClass:"position"},[_c('i-icon',{staticClass:"red",attrs:{"name":"icon-map-marker"}}),_vm._v("到:"),_c('span-address',{attrs:{"lon":row.Lon_E,"lat":row.Lat_E,"show-default":""}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"距离","width":"120","prop":"Mile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('p',{},[_vm._v(_vm._s(row.Mile)+"米")])}}])}),_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"i-link",on:{"click":function (e) { return _vm.playTrack(row); }}},[_vm._v("回放轨迹")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }