<template>
  <el-dialog title="历史行程" width="900px" :visible.sync="visible" top="5vh">
    <el-row type="flex" justify="space-between" align="center">
      <div >
        <span>查询时间：</span>
        <el-date-picker v-model="range" type="datetimerange" range-separator="至" start-placeholde="开始时间" end-placeholde="结束时间">
        </el-date-picker>
        <span class="i-link iml-0" @click="setRangeToday">今天</span>
        <span class="i-link" @click="setRangeYesterday">昨天</span>
        <span class="i-link" @click="setRangeBeforeYesterday">前天</span>
      </div>

      <el-button class="border" type="primary" @click="fetchData" plain>查询</el-button>
    </el-row>
    <el-table :data="list" class="v-table__customStyle" style="margin-top: 12px;" height="500px" v-loading="tableLoading" element-loading-text="拼命加载中">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="时长" width="180">
        <template slot-scope="{row}">
          <p class="clock"><i-icon name="icon-clock"></i-icon>{{row.StartTimeDesc}}~{{row.EndTimeDesc}}</p>
        </template>
      </el-table-column>
      <el-table-column label="位置">
        <template slot-scope="{row}">
          <p class="position"><i-icon name="icon-map-marker" class="blue"></i-icon>从:<span-address :lon="row.Lon_B" :lat="row.Lat_B" show-default></span-address></p>
          <p class="position"><i-icon name="icon-map-marker" class="red"></i-icon>到:<span-address :lon="row.Lon_E" :lat="row.Lat_E" show-default></span-address></p>
        </template>
      </el-table-column>
      <el-table-column label="距离" width="120" prop="Mile">
        <p slot-scope="{row}">{{row.Mile}}米</p>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="{row}">
          <p @click="e => playTrack(row)" class="i-link">回放轨迹</p>
        </template>
        <!-- <p slot-scope="{row}" @click="playTrack(row)" class="i-link">轨迹回放</p> -->

      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import * as API from '@/api/trip'
import miment from 'miment';
export default {
  props: {
  },
  data() {
    return {
      id: null,
      list: [],
      visible: false,
      tableLoading: false,
      range: [miment().add(-1, 'DD'), miment()],
      miment
    }
  },
  methods: {
    open(id) {
      this.visible = true
      this.id = id
      this.fetchData()
    },
    fetchData() {
      this.tableLoading = true
      API.GetTrackTrip(this.id, this.range[0], this.range[1]).then(ret => {
        this.tableLoading = false
        if (!ret.data.errCode) {
          this.list = ret.data.list
        } else {
          this.$message({
            message: ret.data.errMsg,
            type: 'error'
          })
        }
      })
    },
    playTrack(row) {
      //console.log(miment(row.StartTime), miment(row.EndTime), row.Duration)
      this.$emit('play-track', miment(row.StartTime), miment(row.EndTime))
      this.visible = false
    },
    close() {

    },
    setRange(startTime, endTime) {
      this.range = [startTime, endTime]
      this.fetchData()
    },
    setRangeToday() {
      this.setRange(miment().set(0, 'hh').set(0, 'mm').set(0, 'ss'), miment())
    },
    setRangeYesterday() {
      this.setRange(miment().set(0, 'hh').set(0, 'mm').set(0, 'ss').add(-1, 'DD'), miment().set(0, 'hh').set(0, 'mm').set(0, 'ss'))
    },
    setRangeBeforeYesterday() {
      this.setRange(miment().set(0, 'hh').set(0, 'mm').set(0, 'ss').add(-2, 'DD'), miment().set(0, 'hh').set(0, 'mm').set(0, 'ss').add(-1, 'DD'))
    }
  }
}
</script>

<style lang="scss" scoped>
.position {
  display: flex;
  align-items: center;
  .icon {
    font-size: 20px;
    line-height: 22px;
    &.blue {
      color: #1296DB;
    }
    &.red {
      color: #D81E06;
    }
  }
}
.clock {
  display: flex;
  align-items: center;
  .icon {
    font-size: 18px;
    line-height: 23px;
    margin-right: 4px;
    color: #2AA515;
  }
}
</style>
